<template>
  <a-modal
    :title="title"
    width="1000px"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
    :maskClosable="false"
    :keyboard="false"
    class="train-plan-form-dialog"
    v-bind="propsData"
  >
    <a-form-model :model="form" ref="form" :rules="rules" :wrapper-col="{ span: 15 }" :labelCol="{ span: 8 }">
      <div class="type-content">
        <div class="type-content-header">
          <div class="header-title">培训基本情况</div>
        </div>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="培训计划名称" prop="title" :wrapper-col="{ span: 14 }" :labelCol="{ span: 6 }">
              <a-input
                v-model="form.title"
                placeholder="请输入培训计划名称"
                :maxLength="1000"
                allowClear
                :disabled="edit"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="培训类别" prop="categoryId" :wrapper-col="{ span: 14 }" :labelCol="{ span: 6 }">
              <a-cascader
                :disabled="edit"
                :options="cateOption"
                placeholder="请选择培训类别"
                v-model="form.categoryId"
                :show-search="{ filter: filterOption }"
                allowClear
                :fieldNames="{
                  label: 'name',
                  value: 'id',
                  children: 'list',
                }"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </div>
      <div class="type-content">
        <div class="type-content-header">
          <div class="header-title">培训资料信息</div>
          <a-button type="primary" size="small" @click="handleAddMater" v-if="!edit">添加培训资料</a-button>
        </div>
        <a-form-model-item label="" prop="trainingPlanMaterials" :wrapper-col="{ span: 24 }" :labelCol="{ span: 0 }">
          <a-table
            :columns="columns"
            :data-source="form.trainingPlanMaterials || []"
            :scroll="{ y: 300 }"
            :pagination="false"
            row-key="id"
          >
            <template slot="action" slot-scope="text, record">
              <a-popconfirm
                title="确认删除吗？"
                @confirm="handleDeleteMater(record)"
                ok-text="是"
                cancel-text="否"
                v-if="!edit"
              >
                <a-button type="link">删除</a-button>
              </a-popconfirm>
            </template>
          </a-table>
        </a-form-model-item>
      </div>

      <div class="type-content">
        <div class="type-content-header">
          <div class="header-title">培训试题信息</div>
        </div>
        <a-row>
          <a-col :span="8">
            <a-form-model-item label="试卷分值">
              <a-input-number :value="100" disabled style="width: 200px" :precision="0" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="合格分数" prop="score">
              <a-input-number
                v-model="form.score"
                :min="0"
                :max="100"
                style="width: 200px"
                :precision="0"
                :disabled="edit"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="考试时长" prop="duration">
              <a-time-picker
                style="width: 200px"
                v-model="form.duration"
                :disabled="edit"
                allowClear
                :defaultOpenValue="defaultOpenValue"
                placeholder="考试时长"
                valueFormat="HH:mm:ss"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="24" v-if="!edit">
            <a-form-model-item
              label="培训类别"
              prop="trainingPlanTypes"
              :wrapper-col="{ span: 20 }"
              :labelCol="{ span: 3 }"
            >
              <div class="check-content">
                <template v-for="(item, arrIndex) in checkOptionArr">
                  <div class="check-content-item" v-if="!!typeOption[arrIndex].length" :key="item">
                    <div class="check-content-item-title">{{ item }}</div>
                    <div class="check-content-item-box">
                      <a-checkbox
                        v-for="(data, index) in typeOption[arrIndex]"
                        :key="data.categoryId"
                        @change="handleGetNum(data.categoryId)"
                        :checked="(form.trainingPlanTypes || []).includes(data.categoryId)"
                      >
                        {{ data.category }}
                      </a-checkbox>
                    </div>
                  </div>
                </template>
              </div>
              <!-- <a-checkbox-group
                v-model="form.trainingPlanTypes"
                name="trainingPlanTypes"
                :options="typeOption"
                @change="handleGetNum"
              /> -->
            </a-form-model-item>
          </a-col>
          <div style="color: red; margin: 0 0 5px 10px">组题数量相加必须等于50</div>
          <a-col :span="8">
            <a-form-model-item label="单选题数量" prop="num1">
              <div class="input-num-box">
                <a-input-number
                  v-model="form.num1"
                  :min="0"
                  :max="50"
                  style="width: 100px"
                  :precision="0"
                  :disabled="edit"
                />
                <span class="input-num-total">共{{ getNumByST(1) }}题</span>
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="多选题数量" prop="num2">
              <div class="input-num-box">
                <a-input-number
                  v-model="form.num2"
                  :min="0"
                  :max="50"
                  style="width: 100px"
                  :precision="0"
                  :disabled="edit"
                />
                <span class="input-num-total">共{{ getNumByST(2) }}题</span>
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="判断题数量" prop="num3">
              <div class="input-num-box">
                <a-input-number
                  v-model="form.num3"
                  :min="0"
                  :max="50"
                  style="width: 100px"
                  :precision="0"
                  :disabled="edit"
                />
                <span class="input-num-total">共{{ getNumByST(3) }}题</span>
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
      </div>
    </a-form-model>
    <selectMater
      ref="selectMater"
      :cateOption="cateOption"
      :selectData="form.trainingPlanMaterials"
      @change="handleEventAdd"
    />
  </a-modal>
</template>

<script>
import { addTrainPlan, lookTrainPlan, getDataAllCategory, getSTNum } from '@/api/train'
import moment from 'moment'
import selectMater from './select-mater.vue'

export default {
  name: 'FORM',
  components: {
    selectMater
  },
  props: {
    cateOption: {
      type: Array,
      default: () => []
    }
  },
  data () {
    const validMater = (rule, value, callback) => {
      if (value && value.length) {
        callback()
      } else {
        callback(new Error('请添加培训资料'))
      }
    }
    const validPlanType = (rule, value, callback) => {
      if (value && value.length) {
        callback()
      } else {
        callback(new Error('请选择培训类别'))
      }
    }
    return {
      form: {},
      visible: false,
      confirmLoading: false,
      defaultOpenValue: moment('00:00:00'),
      rules: {
        title: { required: true, message: '请输入培训计划名称', trigger: 'blur' },
        categoryId: { required: true, message: '请选择培训类别', trigger: 'change' },
        duration: { required: true, message: '请选择考试时长', trigger: 'blur' },
        score: { required: true, message: '请输入合格分数', trigger: 'blur' },
        trainingPlanTypes: { validator: validPlanType, trigger: 'change' },
        num1: { required: true, message: '请输入单选题数量', trigger: 'blur' },
        num2: { required: true, message: '请输入多选题数量', trigger: 'blur' },
        num3: { required: true, message: '请输入判断题数量', trigger: 'blur' }
      },
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          width: 60,
          customRender: (t, r, i) => i + 1
        },
        {
          title: '资料名称',
          dataIndex: 'title',
          key: 'title',
          ellipsis: true
        },
        {
          title: '培训类别',
          dataIndex: 'categoryId',
          key: 'categoryId',
          customRender: (t, r) => this.formatCate(t, r)
        },
        {
          title: '资料类型',
          dataIndex: 'type',
          key: 'type',
          customRender: (t) => (t == 1 ? '文档' : '视频')
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          width: 80,
          scopedSlots: { customRender: 'action' }
        }
      ],
      typeOption: [[], [], []],
      edit: false,
      cateNumList: [],
      checkOptionArr: ['三级安全教育', '工种/专业', '通用']
    }
  },
  computed: {
    title () {
      return this.edit ? '培训计划详情' : `新增培训计划`
    },
    propsData () {
      return {
        [this.edit ? 'footer' : '']: false
      }
    }
  },
  created () {
    getDataAllCategory().then((res) => {
      this.typeOption = res.data.reduce(
        (dataArr, item) => {
          const index = this.checkOptionArr.findIndex((data) => item.categoryTree.indexOf(data) === 0)
          dataArr[index].push(item)
          return dataArr
        },
        [[], [], []]
      )
    })
  },
  methods: {
    formatCate (t, r) {
      if (r.category) return r.category
      const tArr = t.split(',')
      let list = this.cateOption
      let data
      for (let index = 0; index < tArr.length; index++) {
        const element = tArr[index]
        if (list && list.length) {
          const findData = list.find((item) => item.id === element)
          list = findData ? findData.list : []
          if (index === tArr.length - 1) {
            data = findData
          }
        }
      }
      return data ? data.name : '- -'
    },
    getNumByST (type) {
      const data = this.cateNumList.find((item) => item.type == type)
      return data ? data.num : 0
    },
    handleGetNum (value) {
      const index = (this.form.trainingPlanTypes || []).indexOf(value)
      if (index !== -1) {
        this.form.trainingPlanTypes.splice(index, 1)
      } else {
        this.form.trainingPlanTypes.push(value)
      }
      if (this.form.trainingPlanTypes.length) {
        this.$spin.show()
        getSTNum({ categoryIds: this.form.trainingPlanTypes })
          .then((res) => {
            this.cateNumList = res.data
          })
          .finally(() => {
            this.$spin.hide()
          })
      } else {
        this.cateNumList = []
      }
    },
    handleAddMater () {
      this.$refs.selectMater.createModel()
    },

    filterOption (inputValue, path) {
      return path.some((option) => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    },
    createModel () {
      this.visible = true
      this.edit = false
      this.$set(this, 'form', {
        categoryId: [],
        trainingPlanMaterials: [],
        trainingPlanTypes: []
      })
    },
    lookModel (id) {
      this.$spin.show()
      this.edit = true
      lookTrainPlan(id)
        .then((res) => {
          this.visible = true
          this.$set(this, 'form', { ...res.data, categoryId: res.data.categoryId.split(',') })
        })
        .finally(() => this.$spin.hide())
    },
    handleFormatModel () {
      return {
        ...this.form,
        categoryId: (this.form.categoryId || []).join(','),
        trainingPlanTypes: this.form.trainingPlanTypes.map((item) => ({
          categoryId: item
        }))
      }
    },
    handleSubmit () {
      const { num1 = 0, num2 = 0, num3 = 0 } = this.form
      if (num1 + num2 + num3 != 50) {
        this.$message.warning('组题数量相加必须等于【50】')
        return
      }
      this.$refs.form.validate((valid) => {
        if (!valid) return
        this.confirmLoading = true
        const model = this.handleFormatModel()
        addTrainPlan(model)
          .then((res) => {
            this.$message.success(`${this.title}成功`)
            this.$emit('success')
            this.handleCancel()
          })
          .finally(() => {
            this.confirmLoading = false
          })
      })
    },
    handleCancel () {
      this.visible = false
      this.confirmLoading = false
      this.cateNumList = []
      this.$refs.form.resetFields()
    },
    handleEventAdd (rowList, type) {
      const selectArr = this.form.trainingPlanMaterials
      if (type === 'add') {
        this.form.trainingPlanMaterials.push(
          ...rowList.filter((item) => !selectArr.some((data) => data.id === item.id))
        )
      } else {
        this.form.trainingPlanMaterials = selectArr.filter((item) => !rowList.some((data) => data.id === item.id))
      }
    },
    handleDeleteMater (rowItem) {
      const index = this.form.trainingPlanMaterials.findIndex((item) => item.id === rowItem.id)
      if (index !== -1) {
        this.form.trainingPlanMaterials.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="less">
.train-plan-form-dialog {
  .type-content {
    width: 100%;
    border: 1px solid #eeeeee;
    padding: 8px;
    margin-bottom: 12px;
    &-header {
      height: 40px;
      background-color: #d8d8d8;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 2px;
      padding: 0 8px;
      margin-bottom: 8px;
      .header-title {
        color: #3d3d3d;
        font-size: 20px;
      }
    }
  }
  .input-num-box {
    display: flex;
    align-items: center;
    .input-num-total {
      margin-left: 12px;
      display: inline-block;
    }
  }
}
</style>
